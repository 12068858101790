'use strict';

angular.module('kljDigitalLibraryApp')

.directive('myScroller', function(){

    return {

        restrict: 'A',
        scope:{
        	getMoreRecords: '=getMoreRecords'
        },
        link: function(scope,elem,attrs){
            
            $(elem).on('scroll', function(evt){
               
               if(elem[0]){
                
	               	if(elem.outerHeight() + elem[0].scrollTop >= elem[0].scrollHeight){
	               		
	               		scope.getMoreRecords();
	               	}
               }

            });
        }

    }

});